<template>
  <div>
    <div class="login-div" style="background-size:cover; min-height:100vh">
      <div class="row">
        <div class="col-lg-12 col-12">
   <div style="background:#fff; padding:20px; max-width:500px; margin:0 auto; margin-top:50px;">
                    <div style="margin:0 auto; min-width:100%;display: flex;
    justify-content: center;">

                 <img src="../../assets/images/logo.png" height="90" class="mt-4" @click="sendToMarket">

                    </div>
   <div class="form-div " style="min-height:60vh">
            <form action class="form">
              <h4 class="mt-2 mb-4 head-font">{{tran.createPin}}</h4>

              <div class="form-group">
                <label
                  class="form-label"
                  for="first"
                  @click.prevent="focusOnInput($event)"
                >{{tran.pin}}</label>
                <div class="eye-div">
                  <input
                    v-model="pin"
                    class="form-input"
                    :type="hideInput"
                    autocomplete="off"
                    @keyup="checkEnterButton"
                    maxlength="4"
                  >
                  <i class="fa fa-eye-slash" @click="showInputText" v-if="showEye"></i>
                  <i class="fa fa-eye" @click="hideInputText" v-if="hideEye"></i>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label" for="last">{{tran.retypePin}}</label>
                <input
                  class="form-input"
                  :type="hideInput"
                  autocomplete="off"
                  @keyup="checkEnterButton"
                  v-model="retypePin"
                  maxlength="4"
                >
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="submit-btn mt-2"
                  :class="{'fade-elem': loader || pin !== retypePin}"
                  @click.prevent="createPin">
                  {{ loader ? '' : tran.save }}
                  <span class="loader mt-1" v-if="loader"></span>
                </button>
              </div>
            </form>
          </div>

        </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import trans from "../../../translations";
import { mapState } from "vuex";
import http from "../../http/index";
import api from "../../apis/index";
import { userNotification } from "../dashboard/mixins/notifyUser.js";
export default {
  mixins: [userNotification],
  data() {
    return {
      tran: {},
      hideEye: false,
      showEye: true,
      hideInput: "password",
      pin: "",
      retypePin: ""
    };
  },
  computed: {
    ...mapState({
      formdata: state => state.auth.updatePasswordData,
      loader: state => state.auth.loader,
      token: state => state.auth.token,
            userProfileInfo: state => state.auth.userProfileInfo,
    })
  },
  methods: {
    sendToMarket() {
      this.$router.push("/market");
    },

    
    createPin() {
      let apiData = {
        pin: this.pin
      };

      this.$store.commit("auth/startLoader");
      http
        .post(api.pin, apiData)
        .then(response => {
          this.$helpers.log(response);
           this.$store.commit("auth/stopLoader");
          this.$toast.success({
            title: this.tran.success,
            icon: require("../../assets/images/successicon.png"),
            timeOut: 5000,
            message: "Pin created successfully",
            position: "top center"
          });

          this.$router.push("/profile");
        })
        .catch(error => {
          this.$store.commit("auth/stopLoader");
          this.$helpers.log(error.response);
          if (error.response !== undefined) {
            this.$toast.warn({
              title: this.tran.sorry,
              icon: require("../../assets/images/cautionicon.png"),
              timeOut: 5000,
              message: error.response.data.message,
              position: "top center"
            });
          }
        });
    },
    showInputText() {
      this.hideInput = "text";
      this.showEye = false;
      this.hideEye = true;
    },

    hideInputText() {
      this.hideInput = "password";
      this.showEye = true;
      this.hideEye = false;
    },

    focusOnInput(e) {
      e.target.parentNode.querySelector(".form-input").focus();
    },

    checkEnterButton() {
      if (event.keyCode === 13) {
        this.updatePassword();
      }
    }
  },

  mounted() {
    this.tran = trans;

setTimeout(()=>{

    if(this.token == null || this.userProfileInfo.has_pin !== false){

    this.$router.push('/profile')
}
}, 900)
  },
    beforeCreate() {
        if (this.token !== null) {
      
     this.$store.dispatch('auth/getUserProfile');

        }
    }
};
</script>

<style>
</style>
