<template>
    <CreatePin/>
</template>

<script>
import CreatePin from '../../components/auth/CreatePin'
export default {

    components: {
        CreatePin
    }
}
</script>

<style>

</style>
